import {
  CircularProgress,
  Divider,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { StatRadarGraph, normalizeData } from "../StatRadarGraph";
import {
  Suspense,
  useCallback,
  useMemo,
  useState,
  useRef,
  useEffect,
  forwardRef,
} from "react";
import { courtSubjectsAtom, removeItemAtIndex } from "../../../services/store";

import { AggregateTag } from "../AggregateTag";
import { CompareArrows as CompareArrowsIcon } from "@mui/icons-material";
import Grid from "@mui/material/Unstable_Grid2";
import InfoIcon from "@mui/icons-material/Info";
import PreviewIcon from "@mui/icons-material/Preview";
import { StatPieGraphText } from "../StatPieGraphText";
import { compareDoctrinaState } from "../../../services/store";
import { doctrinaOptions } from "../../../services/queries";
import { hotjar } from "react-hotjar";
import { useAtom } from "jotai";
import { useAtomValue } from "jotai";
import { useContainerQuery } from "react-container-query";
import { useLowSampleThreshold } from "../../../services/hooks";
import { useNavigate } from "react-router-dom";
import { useSuspenseQuery } from "@tanstack/react-query";

const query = {
  "width-between-500-and-820": {
    minWidth: 500,
    maxWidth: 820,
  },
  "width-between-500-and-930": {
    minWidth: 500,
    maxWidth: 930,
  },
};

const StatCardOne = ({ value, label, tooltip = false }) => (
  <Tooltip
    title={tooltip}
    disableHoverListener={!tooltip}
    disableFocusListener={!tooltip}
    disableTouchListener={!tooltip}
  >
    <Stack direction="row" spacing={1}>
      <Typography fontWeight={800}>{value}</Typography>
      <Typography color="text.light">{label}</Typography>
    </Stack>
  </Tooltip>
);

const tabs = ["jurisprudence", "sentiment", "tone"];

const Fetch = forwardRef(
  ({ active, isDetailPage = false, id, buttonsRef }, ref) => {
    const theme = useTheme();
    const [params] = useContainerQuery(query);
    const [comparing, setComparing] = useAtom(compareDoctrinaState);
    const isChecked = comparing.findIndex((compare) => compare === id);
    const lgUp = useMediaQuery(theme.breakpoints.up("lg"));
    const [tab, setTab] = useState(0);

    const LowSampleThreshold = useLowSampleThreshold();

    const subjects = useAtomValue(courtSubjectsAtom(id));

    const parameters = decodeURIComponent(
      new URLSearchParams({
        subject: subjects.map((subject) => subject.id).join(","),
      }).toString()
    );

    const { data: court } = useSuspenseQuery(doctrinaOptions(id, parameters));

    const {
      judges_count,
      jurisprudence,
      sentiment,
      tone,
      name,
      court_opinions_count,
      type,
      is_aggregate,
      is_court_x,
    } = court;

    const toggleCompare = useCallback(() => {
      if (isChecked !== -1) {
        const updated = removeItemAtIndex(comparing, isChecked);
        setComparing(updated);
        hotjar.event("compare-courts-removed");
      } else {
        setComparing((items) => [...items, id]);
        hotjar.event("compare-courts-added");
      }
    }, [comparing, id, isChecked, setComparing]);

    const navigate = useNavigate();

    const goToItem = () => {
      navigate(`/doctrina/${id}`);
    };

    const data = useMemo(() => {
      if (tabs[tab] === "jurisprudence") return jurisprudence;
      if (tabs[tab] === "tone") return tone;
      if (tabs[tab] === "sentiment") return sentiment;
    }, [tab, jurisprudence, tone, sentiment]);

    const handleSetTab = (event, value) => setTab(value);
    const handleSetTabAlt = (event) => setTab(event.target.value);

    const cardRef = useRef(null);

    useEffect(() => {
      if (ref) {
        const currentElement = cardRef.current;
        if (ref.current !== currentElement) {
          ref.current = currentElement;
        }
      }
    }, [ref]);

    return (
      <Paper
        ref={cardRef}
        elevation={5}
        sx={{
          padding: 3,
          borderRadius: 3,
          borderRight:
            (active || isChecked !== -1) &&
            `25px solid ${theme.palette.primary.main}`,
        }}
      >
        <Stack
          alignItems="center"
          flexWrap={[
            "wrap",
            "wrap",
            "wrap",
            params["width-between-500-and-820"] ? "wrap" : "nowrap",
          ]}
          direction={["column", "row"]}
          gap={[2, 3]}
        >
          <Stack
            spacing={1}
            alignSelf="stretch"
            justifyContent="space-between"
            maxWidth={[
              "auto",
              "100%",
              "100%",
              params["width-between-500-and-820"] ? "100%" : 225,
            ]}
            width={[
              "100%",
              "100%",
              "100%",
              params["width-between-500-and-820"] ? "100%" : "auto",
            ]}
          >
            <Stack spacing={2}>
              <Stack spacing={1}>
                <Typography variant="h3" as="h2">
                  {name}
                  {!is_court_x &&
                    court_opinions_count <
                      judges_count * LowSampleThreshold && (
                      <Tooltip
                        sx={{ marginLeft: -1, marginTop: -1 }}
                        title="Limited Sample"
                        placement="right"
                      >
                        <IconButton>
                          <InfoIcon
                            color="primary"
                            sx={{ width: 15, height: 15 }}
                          />
                        </IconButton>
                      </Tooltip>
                    )}
                </Typography>
                <Typography variant="h5" as="h3" color="text.light">
                  {type}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                spacing={1}
                sx={{ minWidth: "225px" }}
              >
                {!is_court_x && (
                  <>
                    <StatCardOne value={judges_count} label="Judges" />
                    <StatCardOne
                      value={court_opinions_count}
                      label="Opinions"
                      tooltip="Sample is taken from 3-5 years of published opinions"
                    />
                  </>
                )}
              </Stack>
              {(is_aggregate === 1 || is_court_x === 1) && (
                <AggregateTag
                  sx={
                    is_court_x
                      ? { border: `2px solid ${theme.palette.primary.main}` }
                      : {}
                  }
                  tooltipText={
                    is_court_x
                      ? "This court represents the average data across all available judges on the platform"
                      : undefined
                  }
                />
              )}
            </Stack>

            {!isDetailPage && (
              <Stack direction="row" ref={buttonsRef} sx={{ mt: 2 }}>
                <Tooltip title={isChecked !== -1 ? "Comparing" : "Compare"}>
                  <IconButton onClick={toggleCompare}>
                    <CompareArrowsIcon
                      color={isChecked !== -1 ? "primary" : "inherit"}
                      data-cy="compare-button-doctrina-card"
                    />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Detailed View">
                  <IconButton onClick={goToItem}>
                    <PreviewIcon sx={{ color: "#fec736" }} />
                  </IconButton>
                </Tooltip>
              </Stack>
            )}
          </Stack>
          {lgUp && !params["width-between-500-and-820"] && (
            <Divider orientation="vertical" flexItem />
          )}
          <Stack flex={1}>
            <Tabs
              value={tab}
              onChange={handleSetTab}
              aria-label="basic tabs example"
              sx={{ display: ["none", "none", "block"], mb: 2 }}
            >
              <Tab label="Jurisprudence" {...a11yProps(1)} />
              <Tab label="Sentiment" {...a11yProps(2)} />
              <Tab label="Tone" {...a11yProps(3)} />
            </Tabs>
            <Select
              sx={{
                display: ["block", "block", "none", "none"],
                width: "100%",
                mb: 3,
              }}
              fullWidth
              onChange={handleSetTabAlt}
              value={tab}
            >
              <MenuItem value={0}>Jurisprudence</MenuItem>
              <MenuItem value={1}>Sentiment</MenuItem>
              <MenuItem value={2}>Tone</MenuItem>
            </Select>
            <Stack flexDirection={["column", "column", "row"]} gap={[1, 1, 6]}>
              <Grid flex={1} spacing={2} container>
                {Object.keys(data).map((key, index) => (
                  <Grid
                    key={index}
                    xs={6}
                    sm={params["width-between-500-and-820"] ? 12 : 4}
                    md={params["width-between-500-and-930"] ? 12 : 4}
                  >
                    <StatPieGraphText
                      comparing={false}
                      color={theme.palette.primary.main}
                      label={key}
                      value={data[key]}
                      isCompare={true}
                      isPrimary={true}
                    />
                  </Grid>
                ))}
              </Grid>
              <Divider orientation="vertical" flexItem />
              <Stack justifyContent="center" alignItems="center">
                <StatRadarGraph
                  data={normalizeData(data)}
                  keys={["judge"]}
                  indexBy="jurisprudence"
                  colors={[theme.palette.primary.main]}
                />
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Paper>
    );
  }
);

export const DoctrinaCard = forwardRef((props, ref) => {
  return (
    <Suspense
      fallback={
        <Stack sx={{ alignItems: "center" }}>
          <CircularProgress />
        </Stack>
      }
    >
      <Fetch {...props} ref={ref} />
    </Suspense>
  );
});

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
