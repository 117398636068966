import React, { useEffect, useState, useCallback, useRef } from "react";
import { useIntercom } from "react-use-intercom";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { styled } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import Draggable from "react-draggable";
import { Tooltip } from "@mui/material";

const DraggableWrapper = styled("div")({
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  pointerEvents: "none",
  zIndex: 2147483000,
  "@media (max-width: 768px)": {
    display: "none",
  },
});

const LauncherButton = styled("button")(
  ({ theme, $isDragging, $isLocked }) => ({
    position: "absolute",
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    backgroundColor: theme.palette.primary.main,
    border: "none",
    cursor: $isLocked ? "pointer" : $isDragging ? "grabbing" : "pointer",
    boxShadow: "0 2px 6px rgba(0,0,0,0.4)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
    pointerEvents: "all",
    transition: $isDragging ? "none" : "transform 0.2s",
    transform: "translate(0, 0)",
    "&:hover": {
      transform: $isDragging || $isLocked ? "none" : "scale(1.1)",
    },
  })
);

const LockButtonContainer = styled("div")({
  position: "absolute",
  top: "-20px",
  right: "-10px",
  zIndex: 1,
});

const LockButton = styled("div")(({ theme, $isVisible }) => ({
  width: "24px",
  height: "24px",
  borderRadius: "50%",
  backgroundColor: theme.palette.grey[500],
  border: "none",
  cursor: "pointer",
  boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "white",
  padding: 0,
  transition: "all 0.2s",
  opacity: $isVisible ? 1 : 0,
  transform: $isVisible ? "scale(1)" : "scale(0.8)",
  "&:hover": {
    transform: "scale(1)",
  },
  "& svg": {
    fontSize: "14px",
  },
}));

const HIDDEN_PATHS = [
  "/login",
  "/reset/password",
  "/reset/request",
  "/register",
  "/maintenance",
];

const DEFAULT_POSITION = {
  x: 20,
  y: window.innerHeight - 250,
};

const BUTTON_WIDTH = 50;
const BUTTON_HEIGHT = 50;
const BUTTON_OFFSET = 70;
const LOCK_BUTTON_TOP_OFFSET = 20;
const LOCK_BUTTON_RIGHT_OFFSET = 10;

const calculateBounds = () => ({
  top: LOCK_BUTTON_TOP_OFFSET,
  left: 0,
  right: window.innerWidth - (BUTTON_WIDTH + LOCK_BUTTON_RIGHT_OFFSET),
  bottom: window.innerHeight - BUTTON_HEIGHT,
});

export const DraggableIntercom = () => {
  const location = useLocation();
  const { show, hide, isOpen } = useIntercom();
  const dragged = useRef(false);
  const nodeRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const [position, setPosition] = useState(() => {
    try {
      const saved = localStorage.getItem("intercomPosition");
      if (saved) {
        const parsed = JSON.parse(saved);
        return { x: parsed.x, y: parsed.y };
      }
    } catch (error) {
      console.error("Error loading saved position:", error);
    }
    return DEFAULT_POSITION;
  });

  const [isLocked, setIsLocked] = useState(() => {
    const saved = localStorage.getItem("intercomLocked");
    return saved ? JSON.parse(saved) : true;
  });

  const updatePosition = (newPosition) => {
    setPosition(newPosition);
    localStorage.setItem("intercomPosition", JSON.stringify(newPosition));
  };

  const updateMessengerPosition = useCallback(() => {
    const messenger = document.querySelector(".intercom-messenger-frame");
    if (!messenger) return;

    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;
    const { x: xPos, y: yPos } = position;

    const isOnRightSide = xPos > viewportWidth / 2;
    const isOnBottomHalf = yPos > viewportHeight / 2;

    if (isOnRightSide) {
      const rightOffset = viewportWidth - xPos + (BUTTON_OFFSET - 65);
      messenger.style.right = `${rightOffset}px`;
      messenger.style.left = "auto";
    } else {
      const leftOffset = xPos + BUTTON_OFFSET;
      messenger.style.left = `${leftOffset}px`;
      messenger.style.right = "auto";
    }

    if (isOnBottomHalf) {
      const bottomOffset = viewportHeight - yPos - BUTTON_HEIGHT;
      messenger.style.bottom = `${bottomOffset}px`;
      messenger.style.top = "auto";
    } else {
      messenger.style.top = `${yPos}px`;
      messenger.style.bottom = "auto";
    }
  }, [position]);

  useEffect(() => {
    if (HIDDEN_PATHS.includes(location.pathname)) {
      hide();
    }
  }, [location.pathname, hide]);

  useEffect(() => {
    window.Intercom("update", { hide_default_launcher: true });

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        mutation.addedNodes.forEach((node) => {
          if (node.classList?.contains("intercom-messenger-frame")) {
            updateMessengerPosition();
          }
        });
      });
    });

    observer.observe(document.body, { childList: true, subtree: true });
    return () => observer.disconnect();
  }, [updateMessengerPosition]);

  useEffect(() => {
    if (isOpen) {
      updateMessengerPosition();
    }
  }, [isOpen, updateMessengerPosition]);

  useEffect(() => {
    const handleResize = () => {
      const bounds = calculateBounds();
      const newX = Math.max(bounds.left, Math.min(position.x, bounds.right));
      const newY = Math.max(bounds.top, Math.min(position.y, bounds.bottom));

      if (newX !== position.x || newY !== position.y) {
        updatePosition({ x: newX, y: newY });
      }

      if (isOpen) {
        updateMessengerPosition();
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [position, isOpen, updateMessengerPosition]);

  const handleLockToggle = (e) => {
    e.stopPropagation();
    const newLockedState = !isLocked;
    setIsLocked(newLockedState);
    localStorage.setItem("intercomLocked", JSON.stringify(newLockedState));
  };

  const handleDragStart = () => {
    if (isOpen || isLocked) return false;
    setIsDragging(true);
    dragged.current = false;
  };

  const handleDrag = () => {
    dragged.current = true;
  };

  const handleDragStop = (e, data) => {
    setIsDragging(false);
    updatePosition({
      x: Math.round(data.x),
      y: Math.round(data.y),
    });

    if (isOpen) {
      updateMessengerPosition();
    }
  };

  const handleClick = () => {
    if (dragged.current) {
      dragged.current = false;
      return;
    }
    if (isOpen) {
      hide();
    } else {
      show();
      let attempts = 0;
      const maxAttempts = 10;
      const retryInterval = 100;

      const positionMessenger = () => {
        const messenger = document.querySelector(".intercom-messenger-frame");
        if (messenger) {
          updateMessengerPosition();
        } else if (attempts < maxAttempts) {
          attempts++;
          setTimeout(positionMessenger, retryInterval);
        }
      };

      setTimeout(positionMessenger, 100);
    }
  };

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const getTooltipPlacement = () => {
    const threshold = 100;
    return position.y < threshold ? "right" : "top";
  };

  if (HIDDEN_PATHS.includes(location.pathname)) {
    return null;
  }

  return (
    <DraggableWrapper>
      <Draggable
        nodeRef={nodeRef}
        position={position}
        onStart={handleDragStart}
        onStop={handleDragStop}
        onDrag={handleDrag}
        bounds={calculateBounds()}
        disabled={isLocked}
      >
        <LauncherButton
          ref={nodeRef}
          onClick={handleClick}
          $isDragging={isDragging}
          $isLocked={isLocked}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <ChatBubbleIcon fontSize="medium" />
          <LockButtonContainer>
            <Tooltip
              title={isLocked ? "Unlock to reposition" : "Lock position"}
              placement={getTooltipPlacement()}
              arrow
              componentsProps={{
                tooltip: {
                  sx: {
                    padding: "6px 10px",
                    fontSize: "0.75rem",
                    whiteSpace: "nowrap",
                  },
                },
              }}
            >
              <LockButton onClick={handleLockToggle} $isVisible={isHovered}>
                {isLocked ? <LockIcon /> : <LockOpenIcon />}
              </LockButton>
            </Tooltip>
          </LockButtonContainer>
        </LauncherButton>
      </Draggable>
    </DraggableWrapper>
  );
};
