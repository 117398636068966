import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { SelectCourt, SelectJudge } from "../../components";
import { Suspense, useCallback, useMemo } from "react";
import {
  createDebriefState,
  uploadDeBriefState,
} from "../../../services/store";
import { useAtomValue, useSetAtom } from "jotai";

import { DeBriefCreateSteps } from "./DeBriefCreateSteps";
import Grid from "@mui/material/Unstable_Grid2";
import { Default as Layout } from "../../layouts";
import { SelectField } from "../../components";
import { TextField } from "../../components";
import { useCreateBrief } from "../../../services/queries";
import { useForm } from "react-hook-form";
import { useIsMutating } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useUploadBriefFile } from "../../../services/models";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export const DeBriefCreate = () => {
  const navigate = useNavigate();
  const { judgeName, courtName, court, isAggregate, isCourtX } =
    useAtomValue(uploadDeBriefState);
  const state = useAtomValue(createDebriefState);

  const mutations = useIsMutating({
    mutationKey: ["brief"],
  });

  const processing = useMemo(() => mutations > 0, [mutations]);

  return (
    <Layout>
      <Box backgroundColor="grey.100" padding={6}>
        <Box position="relative" mb={6}>
          <Button
            startIcon={<ArrowBackIcon />}
            onClick={() => navigate("/debrief")}
            variant="outlined"
            sx={{
              position: "absolute",
              left: 0,
              top: "50%",
              transform: "translateY(-50%)",
            }}
          >
            Back to DeBrief
          </Button>
        </Box>
        <Grid container spacing={2}>
          {state.step === 2 && (
            <Grid xs={12} md={3}>
              <Stack spacing={4}>
                <Typography variant="body2" color="grey.800">
                  Compare to a court or an individual judge.
                </Typography>
                <Stack spacing={3}>
                  <Suspense
                    fallback={<CircularProgress color="inherit" size={30} />}
                  >
                    <SelectCourt />
                  </Suspense>
                  {court && (
                    <Stack spacing={1}>
                      {!isAggregate && !isCourtX && (
                        <Typography variant="body2" color="grey.800">
                          If no judge is chosen, we’ll compare against the
                          court.
                        </Typography>
                      )}
                      <Suspense
                        fallback={
                          <CircularProgress color="inherit" size={30} />
                        }
                      >
                        {!isCourtX ? (
                          isAggregate ? (
                            <Tooltip title="This is an aggregate court, which means the data for this court cannot be attributed to individual judges.">
                              <Stack>
                                <SelectJudge disabled={true} />
                              </Stack>
                            </Tooltip>
                          ) : (
                            <SelectJudge disabled={false} />
                          )
                        ) : null}
                      </Suspense>
                    </Stack>
                  )}
                </Stack>
              </Stack>
            </Grid>
          )}
          <Grid xs={12} md={state.step === 2 ? 9 : 12}>
            <Paper elevation={3}>
              <Box padding={8} position="relative">
                {processing && (
                  <Stack
                    alignItems="center"
                    justifyContent="center"
                    backgroundColor="rgba(255,255,255,.98)"
                    position="absolute"
                    height="100%"
                    width="100%"
                    top={0}
                    left={0}
                    zIndex={3}
                  >
                    <img
                      src="/gifs/loading-brief.gif"
                      alt="loading"
                      width={250}
                    />
                    {judgeName ? (
                      <Typography textAlign="center" variant="body1">
                        Your document is being analyzed with{" "}
                        <strong>{judgeName}</strong>
                      </Typography>
                    ) : (
                      <Typography textAlign="center" variant="body1">
                        Your document is being analyzed with{" "}
                        <strong>{courtName}</strong>
                      </Typography>
                    )}
                  </Stack>
                )}
                <Stack>
                  <Box flexGrow={1}>
                    <DeBriefCreateSteps current={state.step} />
                  </Box>
                  <Divider sx={{ marginY: 5 }} />
                  <Box>
                    {state.step === 1 && <Upload />}
                    {state.step === 2 && <ChooseJudge />}
                    {state.step === 3 && <Process />}
                  </Box>
                </Stack>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </Layout>
  );
};

const Template = ({
  text,
  handleOnClick,
  buttonText,
  buttonDisabled = false,
  processing = false,
}) => (
  <Stack direction={["column-reverse", "row"]} gap={3}>
    <Typography flex={1} color="grey.700">
      {text}
    </Typography>
    <Button
      size="large"
      type="button"
      variant="contained"
      onClick={handleOnClick}
      disabled={buttonDisabled}
    >
      {processing ? <CircularProgress color="inherit" size={30} /> : buttonText}
    </Button>
  </Stack>
);

const Upload = () => {
  const setBrief = useSetAtom(uploadDeBriefState);
  const setState = useSetAtom(createDebriefState);

  const onDrop = useCallback(
    (files) => {
      setBrief((brief) => ({ ...brief, files }));
      setState((state) => ({ ...state, step: 2 }));
    },
    [setBrief, setState]
  );

  const { getRootProps, getInputProps, open } = useUploadBriefFile({ onDrop });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <Template
        text="Press the “Select Document” button beside and select the word or readable PDF document you would like for us to analyze."
        buttonText="Select Document"
        handleOnClick={open}
      />
    </div>
  );
};

const ChooseJudge = () => {
  const { court } = useAtomValue(uploadDeBriefState);
  const setState = useSetAtom(createDebriefState);

  return (
    <Template
      text="Use the search bar on the left side of the screen to select the Judge you are dealing with in this case."
      buttonText="Case Details"
      buttonDisabled={!court}
      handleOnClick={() => setState((state) => ({ ...state, step: 3 }))}
    />
  );
};

const documentTypeOptions = [
  {
    value: "Brief",
    label: "Brief",
  },
  {
    value: "Motion",
    label: "Motion",
  },
  {
    value: "Oral Argument",
    label: "Oral Argument",
  },
  {
    value: "Document",
    label: "Document (Other)",
  },
];

const defaultValues = {
  case_name: "",
  client_number: "",
  matter_number: "",
  document_type: documentTypeOptions[0].value,
};

const Process = () => {
  const navigate = useNavigate();
  const { court, judge, files } = useAtomValue(uploadDeBriefState);
  const { mutateAsync, isPending } = useCreateBrief();
  const setState = useSetAtom(createDebriefState);
  const reset = useSetAtom(uploadDeBriefState);

  const { control, watch } = useForm({
    defaultValues,
  });

  const case_name = watch("case_name");
  const client_number = watch("client_number");
  const matter_number = watch("matter_number");
  const document_type = watch("document_type");

  const handleOnClick = () => {
    mutateAsync(
      {
        court,
        judge,
        files,
        case_name,
        client_number,
        matter_number,
        document_type,
      },
      {
        onSuccess: async ({ brief_id }) => {
          setState(() => ({ step: 1, judge: null }));
          reset({ files: "", judge: "" });
          navigate(`/debrief/case/${brief_id}`);
        },
      }
    );
  };

  return (
    <Stack sx={{ gap: 4 }}>
      <Stack sx={{ flexDirection: "row", gap: 2 }}>
        <TextField
          control={control}
          placeholder="Case Name"
          label="Case Name"
          fullWidth
          name="case_name"
          trim={false}
          size="small"
          helperText="This field is not required"
        />
        <TextField
          control={control}
          placeholder="Client Number"
          label="Client Number"
          fullWidth
          name="client_number"
          trim={false}
          size="small"
          helperText="This field is not required"
        />
        <TextField
          control={control}
          placeholder="Matter Number"
          label="Matter Number"
          fullWidth
          name="matter_number"
          trim={false}
          size="small"
          helperText="This field is not required"
        />
        <SelectField
          label="Document Type"
          placeholder="Document Type"
          name="document_type"
          control={control}
          options={documentTypeOptions}
          size="small"
        />
      </Stack>
      <Template
        text="DeBrief is ready to process the document you uploaded, which may take up to 60 seconds."
        buttonText="Process"
        processing={isPending}
        handleOnClick={handleOnClick}
      />
    </Stack>
  );
};
