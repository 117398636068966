import * as api from "../../../services/api";

import { Autocomplete, TextField } from "@mui/material";

import { judgeSubjectsAtom } from "../../../services/store";
import { useAtom } from "jotai";
import { useQuery } from "@tanstack/react-query";

export const JudgeFilter = ({ id }) => {
  const [subject, setSubjects] = useAtom(judgeSubjectsAtom(id));

  const handleSetSubject = (event, value) => {
    setSubjects(value);
  };

  const {
    data: subjects,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["subjects", id],
    queryFn: async () => await api.get(`/briefs/judge-subjects/${id}`),
    enabled: !!id,
  });

  if (error) {
    console.error("Error fetching subjects:", error);
  }

  return (
    <Autocomplete
      loading={isLoading}
      multiple
      filterSelectedOptions
      size="small"
      sx={{ width: "100%" }}
      onChange={handleSetSubject}
      autoHighlight
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.label}
      noOptionsText={"None"}
      value={subject}
      options={
        subjects?.map((subject) => ({
          label: subject.subject_area,
          id: subject.id,
        })) || []
      }
      renderInput={(params) => (
        <TextField
          {...params}
          cy-data="header-filter-subject"
          placeholder="Subjects"
          label="Filter Scores by Subject"
        />
      )}
    />
  );
};
