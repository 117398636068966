import * as api from "../../../services/api";
import { useNavigate } from "react-router-dom";

import {
  Box,
  CircularProgress,
  IconButton,
  Link,
  MenuItem,
  Select,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
  Button,
} from "@mui/material";
import { Suspense, useMemo, useState } from "react";
import {
  sidebarOpenAtom,
  summaryTabs,
  uploadModalAtom,
} from "../../../services/store";
import { useAtom, useSetAtom } from "jotai";
import { useParams, useSearchParams } from "react-router-dom";

import { DeBriefCaseContainer } from "./DeBriefCaseContainer";
import { DeBriefCaseFilters } from "./DeBriefCaseFilters";
import { DeBriefCaseSummary } from "./DeBriefCaseSummary";
import { DeBriefUploadModal } from "../../components";
import { Default } from "../../layouts";
import { JudgeCourtSummaryTabs } from "../../components/JudgeCourtSummaryTabs";
import { Default as Layout } from "../../layouts";
import { ReWrite } from "../../components";
import { UploadFile } from "@mui/icons-material";
import { memo } from "react";
import { useSuspenseQuery } from "@tanstack/react-query";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const navItems = ["Summary", "Jurisprudence", "Sentiment", "Tone"];

const DeBriefCaseLoading = () => (
  <Default>
    <Stack
      sx={{
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <CircularProgress size="3rem" />
    </Stack>
  </Default>
);

const DeBriefCaseView = memo(() => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const type = useMemo(() => (id === -1 ? "court" : "judge"), [id]);
  const [tab, setTab] = useAtom(summaryTabs(`${type}-${id}`));
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const setModalOpen = useSetAtom(uploadModalAtom(id));
  const setAsideOpen = useSetAtom(sidebarOpenAtom);
  const [subject, setSubject] = useState([]);
  const [judges, setJudges] = useState([]);
  const handleSetTab = (event, value) => setTab(value);
  const handleSetTabAlt = (event) => setTab(event.target.value);

  const { data: current } = useSuspenseQuery({
    queryKey: ["brief", id, subject, judges],
    refetchOnMount: true,
    queryFn: async () => {
      const cased = await api.get(`/briefs/${id}`);
      const isJudge = cased?.judge?.id !== -1;
      const type = isJudge ? "judge" : "court";

      const obj = {};
      if (subject && subject.length) {
        obj[`${type}_subject`] = subject.map((item) => item.id).join(",");
      }
      if (!isJudge && judges) {
        obj.judges = judges.map((item) => item.id).join(",");
      }

      const params = decodeURIComponent(new URLSearchParams(obj).toString());

      return api.get(`/briefs/${id}${params.length > 0 ? `?${params}` : ""}`);
    },
    refetchInterval(query) {
      if (query?.state?.data?.status !== "PENDING") return false;
      return 7500;
    },
  });

  const versionId = useMemo(
    () =>
      searchParams?.get("version_id") ??
      current?.versions?.[current.versions?.length - 1]?.brief_version_id,
    [searchParams, current]
  );

  const versionIndex = useMemo(() => {
    if (versionId && current?.versions && current.versions.length > 0) {
      const index = current.versions.findIndex(
        (version) => version.brief_version_id === +versionId
      );
      return index !== -1 ? index : current.versions.length - 1;
    }
    return 0;
  }, [versionId, current?.versions]);

  const isJudge = useMemo(
    () => current?.judge?.id !== -1,
    [current?.judge?.id]
  );

  const judgeCourtId = useMemo(
    () => (isJudge ? current?.judge?.id : current?.judge?.court?.id),
    [isJudge, current?.judge?.id, current?.judge?.court?.id]
  );

  const handleSetSubject = (event, value) => {
    setSubject(value);
  };

  const handleSetJudges = (event, value) => {
    setJudges(value);
  };

  const handleSetVersion = (event, value) => {
    if (value && current?.versions && current.versions[value.id]) {
      const newVersionId = current.versions[value.id].brief_version_id;
      if (newVersionId) {
        setSearchParams({ version_id: newVersionId });
      }
    }
  };

  const handleResetFilters = () => {
    setSubject([]);
    setJudges([]);
    setSearchParams({});
  };

  if (current?.status === "PENDING") {
    return (
      <Layout>
        <Stack
          alignItems="center"
          justifyContent="center"
          backgroundColor="rgba(255,255,255,.98)"
          position="absolute"
          height="100%"
          width="100%"
          top={0}
          left={0}
          zIndex={3}
        >
          <img src="/gifs/loading-brief.gif" alt="loading" width={250} />
          <Typography textAlign="center" variant="body1">
            Your document needs a bit more time to fully analyze.
          </Typography>
        </Stack>
      </Layout>
    );
  }

  if (current?.error_message) {
    return (
      <Layout>
        <Stack sx={{ paddingY: 5 }}>
          <Typography>{current?.error_message}</Typography>
          <Link href="/debrief">Go Back</Link>
        </Stack>
      </Layout>
    );
  }

  const noDataAvailable =
    !current || !current.versions || current.versions.length === 0;

  const ContentLoading = () => (
    <Stack
      sx={{
        height: "50vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress size={30} />
    </Stack>
  );

  return (
    <>
      <Layout
        asideIntiallyOpen={tab !== 0 && !isMobile && !noDataAvailable}
        aside={
          !noDataAvailable && (
            <ReWrite
              handleSetTab={handleSetTab}
              currentTab={tab}
              items={navItems}
              brief_id={id}
              brief_version_id={
                current?.versions?.[versionIndex]?.brief_version_id
              }
              subItems={
                current?.versions?.[versionIndex]?.[
                  navItems[tab].toLocaleLowerCase()
                ]
              }
              setAsideOpen={setAsideOpen}
            />
          )
        }
      >
        <Box position="relative" mb={-4} pt={12} ml={4}>
          <Button
            startIcon={<ArrowBackIcon />}
            onClick={() => navigate("/debrief")}
            variant="outlined"
            sx={{
              position: "absolute",
              left: [0, 4],
              top: "50%",
              transform: "translateY(-50%)",
            }}
          >
            Back to DeBrief
          </Button>
        </Box>
        {noDataAvailable ? (
          <Stack
            sx={{
              height: "50vh",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              padding: 3,
            }}
          >
            <Typography variant="h3" gutterBottom>
              No data available for the selected filters
            </Typography>
            <Typography variant="body1" paragraph>
              The current combination of filters doesn't contain any results.
              Please try adjusting your selection.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleResetFilters}
            >
              Reset Filters
            </Button>
          </Stack>
        ) : (
          <>
            <Stack
              sx={{
                flexDirection: ["column", "column", "row"],
                justifyContent: "space-between",
                flexWrap: "wrap",
                gap: [2, 2, 5],
                paddingY: 4,
                paddingX: [0, 4],
              }}
            >
              <Box>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    sx={{ display: ["none", "none", "block"] }}
                    value={tab}
                    onChange={handleSetTab}
                    aria-label="basic tabs example"
                  >
                    {navItems.map((item, index) => (
                      <Tab key={item} label={item} {...a11yProps(index)} />
                    ))}
                  </Tabs>
                  <Select
                    sx={{ display: ["block", "block", "none", "none"] }}
                    fullWidth
                    onChange={handleSetTabAlt}
                    value={tab}
                  >
                    {navItems.map((item, index) => (
                      <MenuItem key={`menu-item-${item}`} value={index}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              </Box>
              <Stack
                flexDirection={["column", "row-reverse"]}
                gap={2}
                sx={{ flex: 1, justifyContent: "flex-end" }}
              >
                <Suspense fallback={<CircularProgress size="1rem" />}>
                  <DeBriefCaseFilters
                    handleSetSubject={handleSetSubject}
                    handleSetVersion={handleSetVersion}
                    handleSetJudges={handleSetJudges}
                    cased={current}
                    versionIndex={versionIndex}
                    isJudge={isJudge}
                    isAggregate={current?.judge?.court?.is_aggregate}
                    isCourtX={current?.judge?.court?.is_court_x}
                  />
                </Suspense>
                <Tooltip title="Upload New Version">
                  <IconButton
                    cy-data="upload-version-btn"
                    size="small"
                    onClick={() => setModalOpen(true)}
                  >
                    <UploadFile />
                  </IconButton>
                </Tooltip>
              </Stack>
            </Stack>
            <Suspense fallback={<ContentLoading />}>
              <Box sx={{ paddingX: [0, 4] }}>
                <Box hidden={tab !== 0}>
                  <Stack sx={{ gap: 5 }}>
                    {current.versions[versionIndex] && (
                      <DeBriefCaseSummary
                        brief_id={id}
                        {...current.versions[versionIndex]}
                        {...current.judge}
                      />
                    )}
                    <Suspense fallback={<ContentLoading />}>
                      <JudgeCourtSummaryTabs
                        isJudge={isJudge}
                        judgeCourtId={judgeCourtId}
                        briefSummary={
                          current.versions[versionIndex]?.brief_summary
                        }
                        isAggregate={current?.judge?.court?.is_aggregate}
                        isCourtX={current?.judge?.court?.is_court_x}
                      />
                    </Suspense>
                  </Stack>
                </Box>
                <Box hidden={tab !== 1}>
                  <DeBriefCaseContainer
                    cased={current}
                    type="jurisprudence"
                    versionIndex={versionIndex}
                    subjects={subject}
                  />
                </Box>
                <Box hidden={tab !== 2}>
                  <DeBriefCaseContainer
                    cased={current}
                    type="sentiment"
                    versionIndex={versionIndex}
                    subjects={subject}
                  />
                </Box>
                <Box hidden={tab !== 3}>
                  <DeBriefCaseContainer
                    cased={current}
                    type="tone"
                    versionIndex={versionIndex}
                    subjects={subject}
                  />
                </Box>
              </Box>
            </Suspense>
          </>
        )}
      </Layout>
      {!noDataAvailable && (
        <DeBriefUploadModal
          brief_id={id}
          judgeName={current?.judge?.display_name}
          caseName={current.versions[versionIndex]?.case_name}
          clientNumber={current.versions[versionIndex]?.client_number}
          matterNumber={current.versions[versionIndex]?.matter_number}
          documentType={current.versions[versionIndex]?.document_type}
        />
      )}
    </>
  );
});

export const DeBriefCase = memo(() => (
  <Suspense fallback={<DeBriefCaseLoading />}>
    <DeBriefCaseView />
  </Suspense>
));

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
